import $ from 'jquery';
import "jquery-datetimepicker/build/jquery.datetimepicker.full.min.js";
import "jquery-ui/ui/widgets/sortable.js";

$(document).ready(function(){
  $('.datetimepicker').datetimepicker({
      format: 'Y-m-d H:i',
      step: 1
    });

  $('.timepicker').datetimepicker({
      datepicker:false,
      format: 'H:i',
      step: 1
  })
  $('.sortable').sortable({ group:'nested' });


  var hash = window.location.hash;
  if (hash) {
    $('.nav-tabs a[href="'+hash+'"]').tab("show");
  }

  $('.nav-tabs a').click(function(e){
    window.location.hash = e.target.hash;
  });
});
